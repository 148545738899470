import { FilterOptions, FilterSettings } from './../filter-popup/filter-popup.component';
import { UUID } from 'angular2-uuid';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { ACTION_TYPE, FIELD_TYPE, SORT_TYPE, PROPERTY_NAME } from 'src/app/shared/enums/enums';
import * as OB from 'src/app/utils/objecter';
import * as LS from 'src/app/utils/localstorage';
import { Item, UnitValue, QueryData } from '../../../core/models/common.model';
import { DEBOUNCE_TIME_AUTO_SAVE, KEYS, PAGINATION_PROPERTIES, PATHS } from 'src/app/utils/constants';
import { UnitConversionService } from 'src/app/core/services/unit-conversion.service';
import { FieldLevelUoMSettings } from '../../models/columnSettings';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { UtilitiesService } from 'src/app/core/services/utilitiesservice.service';
import { ValidateResult } from 'src/app/core/services/common/warning.service';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import * as XLSX from 'xlsx';
import { MultiSelect } from 'primeng/multiselect';


const DEFAULT_WIDTH_OF_SECTION = 'col-md-12';

export interface ToolbarEvent {
  action: ACTION_TYPE;
  status?: boolean;
  data?: any; // data for updating table view
  cache?: any; // data for updating caching
}

export interface ToolbarAddEvent {
  index: number;
  action: ACTION_TYPE;
  data: any; // form data
  revertData: any; //form data which revert to API unit
  revertDataUom: any; //form data which revert to API unit & uom
  originDataUom: any; // row of origin data which updated values from form data
}

export interface ToolbarDeleteEvent {
  index: number;
  data: any; // row of table data
  originDataUom: any; // row of origin data
}

export interface ToolbarSelectEvent {
  checked: boolean;
  index: number;
  originRow: any; // row of origin data which updated value from checkbox
  originData: any; // list of origin data which updated values from checkbox
}

export interface ToolbarEventParams {
  index: number;
  action: ACTION_TYPE;
  originRow: any;
  originData: any[];
  viewData: any[];
}

export interface ToolbarCatalog {
  data: Array<any>;
  header: Array<any>;
}

export interface ToolbarCache {
  topRowKey?: string;
  tableKey?: string;
}

export interface ToolbarColumn {
  key: string; 
  i18n: string; 
  fieldType: string;
  path?: string;
  convert?: boolean;
  sort?: SORT_TYPE;
  readonly?: boolean;
  editTable?: boolean;
  multiSelect?: boolean;
  width?: string;
  uom?: boolean;
  removeUom?: boolean;
  removeIfNull? : boolean;
  hidden?: boolean;
  data?: Array<string | number | Item>; //list data for dropdown
  queryData?: QueryData;
  maxlength?: number;
  validators?: Array<string>;
  validatorsWhen?: Array<ValidationExpression>;
  disableWhen?: Expression;
  updatePayloadWhen?: UpdateExpression;
  sourceData?: string;
  defaultItems?: Array<any>;
  hideInput?: boolean;
  isPrimary?: boolean;
}

export interface ValidationExpression {
  key: string;
  condition: string;
  value: string | number;
  type?: string;
  validators: Array<string>;
}

export interface UpdateExpression {
  value: any,
  andExpressions?: Array<Expression>,
  orExpressions?: Array<Expression>
}

export interface Expression {
  key: string;
  condition: string;
  value: string | number;
}

export class ToolbarSharing {
  indexEditing: number;
  submitted: boolean;
  tableView: any[];
  originData: any[];
}

@Component({
  selector: 'app-table-toolbar',
  templateUrl: './table-toolbar.component.html',
  styleUrls: ['./table-toolbar.component.scss']
})
export class TableToolbarComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public event$: BehaviorSubject<ToolbarEvent>;
  @Input() public disabledCatalog = false;
  @Input() public cache: ToolbarCache;
  @Input() public columns: ToolbarColumn[];
  @Input() public data: any[]; 
  @Input() public colSettings: FieldLevelUoMSettings[];
  @Input() public widthOfSection: string = DEFAULT_WIDTH_OF_SECTION  // Width of the content of section: 'col-md-1, col-md-2, .., col-md-12
  @Input() public catalog: ToolbarCatalog;
  @Input() public addText: string = '';
  @Input() public exportToCsvText: string = null;
  @Input() public showToolbar: boolean = true;
  @Input() public deleteAllText: string = '';
  @Input() public sharing: ToolbarSharing;
  @Input() public maxHeight: string;
  @Input() public topRowForm: FormGroup // Control keys will be same name as colNames in columns
  @Input() public hideConfigBtn: boolean = false;
  @Input() public pagination: boolean = false;
  @Input() public filter: boolean = false;
  @Input() public allowDrag: boolean = true;
  @Input() public allowSelectRow: (agrs: any) => boolean;
  @Input() public allowWarningRowFn: (agrs: any) => any;
  @Input() public allowWarningDataFn: (agrs: any) => ValidateResult;
  @Output("onConfig") onConfig = new EventEmitter<any>();
  @Output("onDrop") onDrop = new EventEmitter<any>();
  @Output("onEdit") onEdit = new EventEmitter<any>();
  @Output("onDelete") onDelete = new EventEmitter<any>();
  @Output("onDeleteAll") onDeleteAll = new EventEmitter<any>();
  @Output("onReset") onReset = new EventEmitter<any>();
  @Output("onAdd") onAdd = new EventEmitter<any>();
  @Output("onSelectCatalog") onSelectCatalog = new EventEmitter<any>();
  @Output("onSelectRow") onSelectRow = new EventEmitter<any>();

  public hasDrop: boolean = false;
  public FIELD_TYPE = FIELD_TYPE;
  public indexEditingView: number = -1; 
  public indexEditing: number = -1;
  public indexDeleting: number = -1;
  public isEditing: boolean = false;
  public submitted: boolean = false;
  public showEditButton: boolean = false;
  public hasConfigEvent: boolean = false;
  public showDeleteButton: boolean = false;
  public showDeleteAllButton: boolean = false;
  public showResetButton: boolean = false;
  public showAddButton: boolean = false;
  public showCatalogButton: boolean = false;
  public showCatalogTable: boolean = false;
  public showTopRow: boolean = false;
  public showEmptyPage: boolean = false;
  public validatorData: ValidateResult = {
    invalid: false,
    message: ""
  };
  public hasDeleteAllEvent: boolean = false;
  public hasMaxHeight: boolean = false;
  public emptyObj = {};
  public columnsView: ToolbarColumn[];
  public columnSort: ToolbarColumn;
  public tableView: any[];
  public currentTableView: any[];
  public status: ACTION_TYPE = ACTION_TYPE.INIT;
  public componentDestroyed$: Subject<boolean> = new Subject();
  public PAGINATION_PROPERTIES = PAGINATION_PROPERTIES;
  public pageEvent = {
    itemsPerPage:  PAGINATION_PROPERTIES.ITEMS_PER_PAGE,
    page: PAGINATION_PROPERTIES.DEFAULT_PAGE,
    startItem: PAGINATION_PROPERTIES.START_ITEM,
    endItem:  PAGINATION_PROPERTIES.ITEMS_PER_PAGE
  }
  public filterData: Array<any> = [];
  public showFilterPopup: boolean = false;
  public filterOptions: FilterOptions = {
      key: null,
      header: null,
      filters: []
  };

  public get showExportBtn() {
    return this.exportToCsvText ? true: false;
  }

  public get totalItems() {
    if(this.filter) {
      return this.filterData.length;
    }

    return this.tableView.length;
  }
  public get dropHighlightClass() {
    if(this.hasDrop && this.allowDrag) {
      return 'drop-highlight';
    }
    return '';
  };
  public get dragThemeClass() {
    if(this.hasDrop && this.allowDrag) {
      return this._utilities.adasThemeClass;
    }
    return '';
  };

  constructor(
    public _unitConversion: UnitConversionService,
    public _utilities: UtilitiesService,
  ) {}

  
  ngOnInit() { 
    this.hasDrop = this.onDrop.observers && this.onDrop.observers.length > 0;
    this.showResetButton = this.onReset.observers && this.onReset.observers.length > 0;
    this.showAddButton = this.onAdd.observers && this.onAdd.observers.length > 0;
    this.showEditButton = this.onEdit.observers && this.onEdit.observers.length > 0;
    this.showDeleteButton = this.onDelete.observers && this.onDelete.observers.length > 0;
    this.showDeleteAllButton = this.onDeleteAll.observers && this.onDeleteAll.observers.length > 0;
    this.hasDeleteAllEvent = this.onDeleteAll.observers && this.onDeleteAll.observers.length > 0;
    this.hasConfigEvent = this.onConfig.observers && this.onConfig.observers.length > 0;
    this.showCatalogButton = this.onSelectCatalog.observers && this.onSelectCatalog.observers.length > 0;
    this.showTopRow = this.topRowForm ? true: false;
    this.hasMaxHeight = this.maxHeight ? true: false;
    this.columnsView = this.columns.filter(x=>x.hidden != true);
    this.columnSort = this.columns.filter(x=>x.sort != undefined)[0];
    this.buildEmptyObj();
    this.listenEvent();
    this.handleTopRowCache();
    this.updateShowDeleteAllButton();
    this.updateShowEmptyPage();
    this.sortTableView();
  }

  ngOnChanges(changes) {
    this.listenDataChanges(changes);
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  private updateTableCache() {
    if(this.cache && this.cache.tableKey) {
      LS.setLocalStorage(this.cache.tableKey, this.data);
    }
  }

  drop(event: CdkDragDrop<any[]>) {
    this.onDrop.emit(event);
  }

  private handleTopRowCache() {
    if(this.cache && this.cache.topRowKey) {
      let cached = LS.getLocalStorage(this.cache.topRowKey);
      if(cached) {
        this.indexEditing = cached.indexEditing;
        this.indexEditingView = cached.indexEditing;
        this.isEditing = this.indexEditing > -1 ? true: false;
        this.updateSharing();
        if(this.colSettings) {
          this._unitConversion.convertObject(this.colSettings, cached.data);
        }
        this.topRowForm.patchValue(cached.data);
      }

      this.topRowForm.valueChanges.pipe(debounceTime(DEBOUNCE_TIME_AUTO_SAVE)).subscribe(
        (data) => {
          let topRow = OB.clone(data);
          if(this.colSettings) {
            this._unitConversion.revertObject(this.colSettings, topRow);
          }
          const cache = {
            data: topRow,
            indexEditing: this.indexEditing
          }
          LS.setLocalStorage(this.cache.topRowKey, cache);
        });

    }
  }

  private updateSharing() {
    if(this.sharing) {
      this.sharing.indexEditing = this.indexEditing;
      this.sharing.submitted = this.submitted;
      this.sharing.tableView = this.tableView;
      this.sharing.originData = this.data;
    }
  }

  private listenEvent() {
    if(this.event$ == undefined || this.event$ == null) {
      return;
    }

    this.event$.pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.status = res.action;

      switch(res.action) {
        case ACTION_TYPE.CREATE:
          this.finishAdd(res.data, res.status);
          break;
        case ACTION_TYPE.UPDATE:
          this.finishEdit(res.data, res.status);
          break;
        case ACTION_TYPE.DELETE:
          this.finishDelete(res.status);
          break;
        case ACTION_TYPE.RESET:
          this.goReset();
          break;
        case ACTION_TYPE.SHOW_CATALOG:
          this.showCatalog(res.data);
          break;
      }
    });
  }

  private listenDataChanges(changes) {   
      this.resetFilterOptions();
      this.updateTableView(changes.data);
      this.sortTableView();
      this.pageChanged(this.pageEvent);
      this.updateShowDeleteAllButton();
      this.updateShowEmptyPage();
      this.updateSharing();
      this.updateTopRowValidity();
  }

  private updateTableView(data) {
    if(data) { 
      this.tableView = [];
      if(data.currentValue) {
        for(const row of data.currentValue) {
          this.tableView.push(this.mapData(row));
        }
        this.updateDataWarning({originData: data.currentValue} as ToolbarEventParams);
      }
    }
    this.filterData = this.tableView;
  }

  private paginationView() {
    this.currentTableView = this.getRangeItems(0, this.pageEvent.itemsPerPage);
  }

  private updateShowDeleteAllButton() { 
    if(this.hasDeleteAllEvent) {
      if(this.tableView.length > 0) {
        this.showDeleteAllButton = true;
      } else {
        this.showDeleteAllButton = false;
      }
    }
  }

  private updateShowEmptyPage() { 
    if(this.showTopRow) {
      this.showEmptyPage = false;
    } else {
      if(this.tableView.length > 0) {
        this.showEmptyPage = false;
      } else {
        this.showEmptyPage = true;
      }
    }
  }

  private mapData(data) {
    let obj = new Object();
    for(const col of this.columns) {
      const path = col.path ? col.path : col.key;
      if(col.convert) {
        const unitColumn = this.getUnitColumn(col.key);
        let convert = this._unitConversion.convertValue(unitColumn, OB.getObj(data, path));
        // if(isNaN(convert) == false) {
        //   convert = Number(convert.toFixed(unitColumn.decimalPrecision + 4)); // This is for validation with current unit.
        // }
        obj[col.key] = convert;
      } else {
        obj[col.key] = OB.getObj(data, path);
      }
    }

    OB.updateObj(obj, "rowId", UUID.UUID()); //this is to detecting row
    //This is for well sight feature
    if(data.hasOwnProperty(PROPERTY_NAME.IsWellSightUsed)) {
      OB.updateObj(obj, PROPERTY_NAME.IsWellSightUsed, data[PROPERTY_NAME.IsWellSightUsed]);
    }

    if(data.hasOwnProperty(KEYS.toolbar.action)) {
      OB.updateObj(obj, KEYS.toolbar.action, data[KEYS.toolbar.action]);
    }

    const validator = this.updateRowWarning({originRow: data} as ToolbarEventParams);
    if(validator.invalid) {
      OB.updateObj(obj, KEYS.toolbar.showWarning, true);
      OB.updateObj(obj, KEYS.toolbar.warningMessage, validator.message);
    }

    return obj;
  }

  private updateDataWarning(params: ToolbarEventParams) {
    this.validatorData.invalid = false;
    if(this.allowWarningDataFn) {
      this.validatorData = this.allowWarningDataFn(params);
    }
  }

  private updateRowWarning(params: ToolbarEventParams): ValidateResult {
    if(this.allowWarningRowFn) {
      const validator =  this.allowWarningRowFn(params);
      if(typeof validator == "boolean") {
        return {
          invalid: validator,
        }
      } else {
        return validator;
      }
    }

    return {
      invalid: false
    }
  }

  private getUnitColumn(key: string) {
    if(this.colSettings) {
      return this.colSettings.filter(x=>x.colName.toLowerCase() == key.toLowerCase())[0];
    }

    return null;
  }

  private finishEdit(row, success) {
    if(success) {
      this.data[this.indexEditing] = row;
      this.tableView[this.indexEditing] = this.mapData(row);
      this.sortTableView();
      this.resetFilterOptions();
      this.transform();
      this.pageChanged(this.pageEvent);
      this.updateTableCache();
      this.updateDataWarning({originData: this.data} as ToolbarEventParams);
      this.goReset();
    }
  }

  private sortTableView() {
    if(this.columnSort) {
      OB.sortArr(this.tableView, this.columnSort.key, this.columnSort.sort);
      let path = this.columnSort.path ? this.columnSort.path: this.columnSort.key;
      OB.sortArr(this.data, path, this.columnSort.sort);
      if(this.filter) {
        OB.sortArr(this.filterData, this.columnSort.key, this.columnSort.sort);
      }
    }
  }

  private finishAdd(row, success) {
    if(success) {
      this.data.push(row);
      this.tableView.push(this.mapData(row));
      this.sortTableView();
      this.resetFilterOptions();
      this.transform();
      this.pageChanged(this.pageEvent);
      this.updateShowDeleteAllButton();
      this.updateTableCache();
      this.updateDataWarning({originData: this.data} as ToolbarEventParams);
      this.goReset();
    }
  }

  private finishDelete(success) {
    if(success) {
      this.data.splice(this.indexDeleting, 1);
      this.tableView.splice(this.indexDeleting, 1);
      this.indexDeleting = -1;
      this.updateShowDeleteAllButton();
      this.resetFilterOptions();
      this.transform();
      this.pageChanged(this.pageEvent);
      this.updateTopRowValidity();
      this.updateTableCache();
      this.updateDataWarning({originData: this.data} as ToolbarEventParams);
    }
  }

  private buildEmptyObj() {
    for(const col of this.columns) {
      if(col.fieldType == FIELD_TYPE.TEXT) {
        this.emptyObj[col.key] = "";
      }
      if(col.fieldType == FIELD_TYPE.NUMBER) {
        this.emptyObj[col.key] = 0;
      }
      if(col.fieldType == FIELD_TYPE.DROPDOWN) {
        this.emptyObj[col.key] = null;
      }
      if(col.fieldType == FIELD_TYPE.CHECKBOX) {
        this.emptyObj[col.key] = false;
      }
      if(col.fieldType == FIELD_TYPE.RADIO_BUTTON) {
        this.emptyObj[col.key] = null;
      }
      if(col.fieldType == FIELD_TYPE.MULTISELECT) {
        this.emptyObj[col.key] = this.topRowForm.controls[col.key].value;
      }

    }
  }

  private patchTopRowValue(obj: Object) {
    let item = {};
    for(const col of this.columns) {
      if(obj.hasOwnProperty(col.key)) {
        item[col.key] = obj[col.key];
      }
    }

    this.topRowForm.patchValue(item);
  }

  private updateTopRowValidity() {
    if(this.topRowForm) {
      let controls = this.topRowForm.controls;
      for(let key in controls) {
        this.topRowForm.controls[key].updateValueAndValidity();
      }
      this.topRowForm.updateValueAndValidity();
    }
  }

  public trackByFn(index, item) {
    return index;
  }

  public getValueOfObject(item, str, fieldType) {    
    return OB.getObj(item, str, fieldType);
  }

  public getDropdownLabel(item, key) {
    const id = item[key];
    const column = this.columns.filter(x=>x.key == key)[0];
    if(id && column && column.data && column.data[0] instanceof Item) {
      for(const row of column.data) {
        if(row == id) {
          return id;
        }
        if(row instanceof Item && row.value == id) {
          return row.label;
        }
      }
    }

    return id;
  }

  public isShowUomLabel(col) {
    if (!this.colSettings || !col.key) return false;
    if(col.uom == false) return false;
    const unitColumn = this.getUnitColumn(col.key);
    return (unitColumn && unitColumn.currentUnit) || false;
  }

  public toggleCatalog() {
    this.showCatalogTable = !this.showCatalogTable;
  }

  private revertObjectUom(obj) {
    if(this.colSettings) {
      let res = OB.clone(obj);
      this._unitConversion.revertObject(this.colSettings, res);
      for(const key in res) {
        let col = this.getUnitColumn(key);
        if(col) {
          res[key] = new UnitValue({uom: col.unit, value: res[key]});
        }
      }

      return res;
    }

    return obj;
  }

  private originObjectUom(obj, index) {
    let revert = this.revertObject(obj);
    let row = OB.clone(this.data[index]);
    for(const col of this.columns) {
      const path = col.path ? col.path: col.key;
      const unitColumn = this.getUnitColumn(col.key);
      const value = col.convert ? revert[col.key]: obj[col.key];

      OB.updateObj(row, path, value);
      if(unitColumn && path.endsWith(".value")) {
        const uomPath = path.replace(".value", ".uom");
        const uomValue = col.convert ? unitColumn.unit: unitColumn.currentUnit;
        OB.createObjIfNotExist(row, uomPath, uomValue);
      }
    }

    return row;
  }

  private revertObject(obj) {
    if(this.colSettings) {
      let res = OB.clone(obj);
      this._unitConversion.revertObject(this.colSettings, res);
      return res;
    }

    return obj;
  }

  public selectCatalog(event) {
    this.showCatalogTable = false;
    this.patchTopRowValue(event);
    this.onSelectCatalog.emit(event);
  }
  
  public goAdd(event) {
    this.submitted = true;
    this.updateSharing();

    if(this.status != ACTION_TYPE.PROCESSING) {
      if(this.topRowForm.invalid) {
        return;
      }

      this.status = ACTION_TYPE.PROCESSING;
      const formValue = this.topRowForm.getRawValue();
      const revertDataUom = this.revertObjectUom(formValue);
      const revertData = this.revertObject(formValue);
      let originDataUom = revertDataUom;
      let actionType = this.isEditing? ACTION_TYPE.UPDATE: ACTION_TYPE.CREATE;
      if(actionType == ACTION_TYPE.UPDATE) {
        originDataUom = this.originObjectUom(formValue, this.indexEditing);
      }
      this.onAdd.emit({
        index: this.indexEditing,
        action: actionType,
        data: formValue,
        revertData: revertData,
        revertDataUom: revertDataUom,
        originDataUom: originDataUom
      });
    }
  }

  public goReset() {
    this.submitted = false;
    this.indexEditing = -1;
    this.indexEditingView = -1;
    this.isEditing = false;
    this.updateSharing();
    this.topRowForm.patchValue(this.emptyObj);
    this.onReset.emit();
  }

  private showCatalog(data) {
    this.showCatalogTable = data;
  }

  public goEdit(index, item) {
    this.indexEditingView = index;
    this.indexEditing = this.findIndex(item, index);
    console.log("Index_edit", this.indexEditing);
    this.isEditing = true;
    this.updateSharing();
    this.topRowForm.patchValue(item);
    this.onEdit.emit({index: this.indexEditing, data: item});
  }

  public goConfiguring(index, item) {
    let indexing = this.findIndex(item, index);
    const originDataUom = this.data[indexing];
    this.onConfig.emit({index: indexing, data: item, originDataUom: originDataUom});
  }

  public goDeleting(index, item) {
    if(this.status != ACTION_TYPE.PROCESSING) {
      this.status = ACTION_TYPE.PROCESSING;
      this.indexDeleting = this.findIndex(item, index);
      console.log("Index_delete", this.indexDeleting);
      const originDataUom = this.data[this.indexDeleting];
      this.onDelete.emit({index: this.indexDeleting, data: item, originDataUom: originDataUom});
    }
  }

  public goDeletingAll() {
    this.onDeleteAll.emit();
  }

  public findIndex(row, viewIndex) {

    if(this.pagination) {
      for(let i = 0; i < this.tableView.length; i++) {
        if(this.tableView[i].rowId == row.rowId) {
          return i;
        }
      }
    } else {
      return viewIndex;
    }

    return -1;
  }

  public selectRowChange(event, column, index, item) {
    const path = column.path ? column.path : column.key;
    const selectedIndex = this.findIndex(item, index);
    const checked = event.currentTarget.checked;
    let originRow = this.data[selectedIndex];

    if (this.allowSelectRow && this.allowSelectRow({ index: selectedIndex, checked: checked, originRow: originRow }) == false) {
      event.currentTarget.checked = !event.currentTarget.checked;
      return;
    }

    if (column.multiSelect == false) {
      for(let row of this.data) {
        OB.updateObj(row, path, false);
      }
      for(let row of this.tableView) {
        OB.updateObj(row, column.key, false);
      }
    }


    OB.updateObj(originRow, path, checked);
    OB.updateObj(this.tableView[selectedIndex], column.key, checked);

    this.onSelectRow.emit({
      index: selectedIndex,
      checked: checked,
      originRow: originRow,
      originData: this.data,
    });
  }

  public pageChangedEvent(event: PageChangedEvent): void {
    this.goReset();
    this.pageChanged(event);
  }

  public pageChanged(event: PageChangedEvent): void {
    const startItem = (event.page - 1) * event.itemsPerPage;
    const endItem = event.page * event.itemsPerPage;

    this.pageEvent.page = event.page;
    this.pageEvent.itemsPerPage = event.itemsPerPage;
    this.pageEvent.startItem = startItem;
    this.pageEvent.endItem = endItem;

    this.currentTableView = this.getRangeItems(startItem, endItem);
  }

  public disabledEditBtn(row) {
    return OB.getObj(row, PATHS.toolbar.allowEdit) == false ? true: false;
  }

  public warningStatus(item) {
    return item[KEYS.toolbar.showWarning] ? true: false;
  }

  public warningMess(item) {
    return item[KEYS.toolbar.warningMessage];
  }

  public get hasWarning() {
    if(this.allowWarningRowFn) {
      return true;
    }

    return false;
  }

  public disabledDeleteBtn(row) {
    if(row[PROPERTY_NAME.IsWellSightUsed] && row[PROPERTY_NAME.IsWellSightUsed] == true) {
      return true;
    }

    return OB.getObj(row, PATHS.toolbar.allowDelete) == false ? true: false;
  }

  public getRangeItems(startItem, endItem) {
    let result = this.tableView;
    if(this.filter) {
      result = this.filterData;
    } 

    if(this.pagination) {
      result = result.slice(startItem, endItem);
    }

    return result;
  }

  public openFilter(event: any, item: ToolbarColumn) {
    this.filterOptions = {
      key: item.key,
      header: item.i18n,
      filters: this.filterOptions.filters
    }
    this.showFilterPopup = true;
  }

  public closeFilter() {
    this.showFilterPopup = false;
  }

  public handleOnSort(filters) {
    this.filterOptions.filters = filters;
    let filter = this.filterOptions.filters.filter(x=>x.key == this.filterOptions.key)[0];
    this.columnSort = this.columns.filter(x=>x.key == this.filterOptions.key)[0];
    this.columnSort.sort = filter.sort;
    this.sortTableView();
    this.currentTableView = this.getRangeItems(this.pageEvent.startItem, this.pageEvent.endItem);
  }

  public handleOnCheckField(filters){
    this.filterOptions.filters = filters;
    this.transform();
    this.pageEvent.page = 1;
    this.pageChangedEvent(this.pageEvent);
  }

  public isFiltering(key) {
    for(let filter of this.filterOptions.filters) {
      if(filter.key == key && filter.fvalues.length > 0) {
        return true;
      }
    }

    return false;
  }

  private resetFilterOptions() {
    for(let filter of this.filterOptions.filters) {
      filter.fvalues = [];
    }
  }

  private transform() {
    if(this.filter) {
      this.filterData = this.tableView;
      for (let filter of this.filterOptions.filters) {
        this.filterData = this.filterable(this.filterData, filter);
      }
    }
  }

  private filterable(data: Array<any>, filter: FilterSettings) {
    let result = [];
    if(filter.fvalues.length == 0) {
      return data;
    }

    for(let fvalue of filter.fvalues) {
      let temp = data.filter(x=> this.isMatchFilter(x[filter.key], fvalue));
      result = result.concat(temp);
    }

    return result;
  }

  private isMatchFilter(filter, fvalue) {
    if (Array.isArray(filter) && filter.length > 0) {
      for (let child of filter) {
        if (child === fvalue) {
          return true;
        }
      }
      return false;
    }

    return filter === fvalue;
  }
  
  public exportToCsv() {
    const workSheetConfig = {
      name: this.exportToCsvText,
      data: this.createExportData()
    }
    let workBook = XLSX.utils.book_new();
    let workSheet = XLSX.utils.aoa_to_sheet(workSheetConfig.data);
    //For excel only
    // workSheet['!cols'] = [
    //   { wch: 100 },
    //   { wch: 100}
    // ];
    XLSX.utils.book_append_sheet(workBook, workSheet, workSheetConfig.name);
    const fileNameExport = workSheetConfig.name.replace(/\s+/g, '') + new Date().getTime().toString() + '.csv';
    XLSX.writeFile(workBook, fileNameExport);
  }

  createExportData() {
    let results = [];
    let headers = this.columns.map(x => { return {key: x.key, header: this._utilities.getTranslationNow(x.i18n)} } );
    results.push(headers.map(x => x.header));

    let exportData = this.tableView;
    if(this.filter) {
      exportData = this.filterData;
    }
    for(let rowView of exportData) {
      let row = [];
      for(let h of headers) {
        row.push(rowView[h.key]);
      }
      results.push(row);
    }
    return results;
  }

  markDisabledOptions(isReadOnly: boolean, multiSelect: MultiSelect) {
    if (isReadOnly) {
      setTimeout(() => {
        const panel = multiSelect.overlay;
        const checkboxes = panel?.querySelectorAll('.ui-multiselect-item');
        checkboxes.forEach((checkbox: any) => {
          const clone = checkbox.cloneNode(true);
          checkbox.replaceWith(clone);
        });
      });
    }
  }
}

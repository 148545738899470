import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { BehaviorSubject, Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import { API_RESPONSE, DEPTH_UNIT, UNITS } from 'src/app/shared/enums/enums';
import { Quantity, SetActiveUnitSystem, UnitSystem } from 'src/app/shared/models/uom-models';
import { CURRENT_UNITS, UNIT_TYPE_QUANTITY } from 'src/app/utils/constants';
import { deepCopy } from 'src/app/utils/utils';
import { firstBy } from 'thenby';
import { environment } from '../../../environments/environment';
import { JobUnitSystem, Settings } from '../models/settingsModel.model';
import { UserModel } from '../models/userModel';
import { ApiService } from './api.service';
import { ColumnSettingsInfo } from './columnSettings';
import { Config } from './config';
import { UnitConversionService } from './unit-conversion.service';
import { UtilitiesService } from './utilitiesservice.service';
import * as LS from 'src/app/utils/localstorage';

@Injectable({
  providedIn: 'root'
})

export class UnitSystemsService {
  public unitSystem: UnitSystem;
  public unitSystems: Array<UnitSystem> = [];
  public globalUnitSystem: UnitSystem;
  public isJobUnitSystem: boolean = false;
  public catQuantityUnits: Array<Quantity> = [];
  public globalUomProfile$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  // used by components
  public selectedUnitSystem: UnitSystem;
  public selectedBaseUnitSystem: UnitSystem;

  constructor(
    public _auth: MsalService,
    public _api: ApiService,
    public _unitConversion: UnitConversionService,
    public _utilities: UtilitiesService,
  ) {
    //this.loadUnitsystemQuantityUnits(true);
  }

  public loadUnitsystemQuantityUnits(forceLoad) {
    var key = LS.LOCAL_STORAGE_KEY.Catalogs.UnitSystem.QuantityUnits;
    if (!forceLoad) { var data = LS.getLocalStorage(key); }
    if (data) {
      this.catQuantityUnits = data;
      return data;
    }
    else {
      var url = Config.APIUrlCatalogs + environment.catalogs.quantityunits;
      return this._api.get<Quantity[]>(url).toPromise()
        .then((data) => {
          this._utilities.storeCatalogs(key, data);
          this.catQuantityUnits = data;
          return data;
        })
        .catch((err) => {
          return err;
        });
    }
  }

  // calls api to get all unitsystems available to a user, called in app initializer
  async getUnitSystemsByUserId(userId: string) {
    //var url = Config.APIUrlCore + environment.unitSystem.getUnitSystemsByUserId.replace("{userId}", userId);
    var url = Config.APIUrlCore + environment.user.getUnitSystems.replace("{userId}", userId);
    return await this._api.get<UnitSystem[]>(url).toPromise()
      .then(data => {
        //var data1 = data.sort(firstBy(function (x:UnitSystem) { return x.unitSystemId; }).thenBy("name"));
        data = data.sort(firstBy(x => x.unitSystemId));
        if (!this._utilities.currentUser) this._utilities.currentUser = new UserModel();
        this._utilities.currentUser.userId = userId;
        this._utilities.currentUser.settings = new Settings();
        this._utilities.currentUser.settings.unitSystems = data;
        this.unitSystems = this._utilities.currentUser.settings.unitSystems;
        return API_RESPONSE.SUCCESS;
      })
      .catch(err => {
        return err;
      });
  }

  // don't delete, may need api-based method
  // calls api to get a specific unitsystem
  async getUnitSystemsByUnitSystemId(unitSystemId: string) {
    var url = Config.APIUrlCore + environment.unitSystem.getUnitSystemsBySystemId.replace("{unitSystemId}", unitSystemId);
    return await this._api.get<UnitSystem>(url).toPromise()
      .then(resp => {
        this.unitSystem = resp;
        return API_RESPONSE.SUCCESS;
      })
      .catch(err => {
        return err;
      });
  }

  // don't delete, may need api-based method
  // calls api to get global unitsystem for user, called in app initializer
  // async getActiveUserUnitSystem(userId: string) {
  //   var url = Config.APIUrlCore + environment.user.getActiveUnitSystem.replace("{userId}", userId);
  //   return await this._api.get<string>(url).toPromise()
  //     .then(resp => {
  //       this.globalUnitSystemId$.next(resp);
  //       return API_RESPONSE.SUCCESS;
  //     })
  //     .catch(err => {
  //       return err;
  //     });
  // }

  // don't delete, may need api-based method
  // async getCurrentUnitSystem(jobId: string, userId: string) {
  //   CURRENT_UNITS.Loading = true;
  //   CURRENT_UNITS.Loaded = false;
  //   let acct = this._auth.getAccount();
  //   var url = Config.APIUrlCore + environment.unitSystem.getActiveJobUnitSystem;
  //   url = url.replace("{jobId}", jobId ? jobId : '-1');
  //   url = url.replace("{userId}", acct.userName);
  //   return await this._api.get<UnitSystem>(url).pipe(take(1)).toPromise()
  //     .then(unitSystemResponse => {
  //       this.setCurrentUnitSystem(unitSystemResponse);
  //     })
  //     .catch(err => {
  //       return err;
  //     });
  // }

  // call api to set global unitsystem, then set current unit system
  // setActiveUserUnitSystem(unitSystemId: string, userId: string) {
  //   var data = new SetActiveUnitSystem();
  //   data.unitSystemId = unitSystemId;
  //   data.userId = userId;
  //   var url = Config.APIUrlCore + environment.user.postActiveUserUnitSystem;
  //   this._api.post<any>(url, data).subscribe(data => {
  //     this._utilities.currentUser.settings.activeUnitSystemId = unitSystemId;
  //     this.setCurrentUnitSystem(this._utilities.jobId$.value, unitSystemId, false);
  //     // trigger observabvle to update settings page
  //     this._utilities.userModelObj$.next(this._utilities.currentUser);
  //   },
  //     (err) => {
  //       return err;
  //     });
  // }

  // calls api to set active unit system, either for a user or a job
  async setActiveUnitSystem(unitSystemId: string, userId: string, jobId: string) {
    var isUser = (jobId === '');
    var data = new SetActiveUnitSystem();
    data.unitSystemId = unitSystemId;
    data.userId = userId;
    data.jobId = jobId;
    var url = isUser ? environment.user.postActiveUserUnitSystem : environment.user.postActiveJobUnitSystem;
    url = Config.APIUrlCore + url;
    return await this._api.post(url, data).toPromise()
      .then(resp => {
        if (isUser) {
          // update user and trigger user observable to update other pages
          this._utilities.currentUser.settings.activeUnitSystemId = unitSystemId;
          this._utilities.userModelObj$.next(this._utilities.currentUser);
        }
        if (!isUser) this.setJobUnitSystem(jobId, unitSystemId);
        this.setCurrentUnitSystem(jobId);
        return API_RESPONSE.SUCCESS;
      })
      .catch(err => {
        return err;
      });
  }
  // uses old endpoint
  // async setActiveJobUnitSystem(unitSystemId: string, jobId: string) {
  //   var url = Config.APIUrlCore + environment.unitSystem.setActiveJobUnitSystem;
  //   url = url.replace("{unitSystemId}", unitSystemId);
  //   url = url.replace("{jobId}", jobId);
  //   return await this._api.post(url, '').toPromise()
  //     .then(resp => {
  //       this.setCurrentUnitSystem(jobId, unitSystemId, true);
  //       return API_RESPONSE.SUCCESS;
  //     })
  //     .catch(err => {
  //       return err;
  //     });
  // }

  // call api to delete a custom unitsystem
  async deleteUnitSystem(unitSystemId: string, unitSystemUserId: string) {
    var url = Config.APIUrlCore + environment.unitSystem.deleteUnitSystem;
    url = url.replace("{unitSystemId}", unitSystemId);
    url = url.replace("{unitSystemUserId}", unitSystemUserId);
    return await this._api.post(url, '').toPromise()
      .then(resp => {
        // remove from user unitsystems
        this.unitSystems = this.unitSystems.filter(x => x.unitSystemId != unitSystemId);
        this._utilities.currentUser.settings.unitSystems = this.unitSystems;
        // remove from job unitsystems
        this.deleteJobUnitSystemsByUnitSystemId(unitSystemId);
        // if we deleted the global, set global to API
        if (this.globalUnitSystem.unitSystemId === unitSystemId) {
          this.globalUnitSystem = this.unitSystems.find(x => x.unitSystemId === UNITS.API_ID);
          this.setActiveUnitSystem(UNITS.API_ID, this._utilities.currentUser.userId, '');
        }
        // if we deleted the current, then set current unit system 
        if (this._unitConversion.currentUnitSystem$.value.unitSystemId === unitSystemId) {
          unitSystemId = this.globalUnitSystem.unitSystemId;
          this.setSelectedUnitSystem(unitSystemId);
          this.setCurrentUnitSystem(this._utilities.jobId$.value);
        }
        return API_RESPONSE.SUCCESS;
      })
      .catch(err => {
        return err;
      });
  }

  // calls api to create or update a custom unitsystem
  async createUpdateUnitSystem(unitSystemModel: any) {
    var url = Config.APIUrlCore + environment.unitSystem.postCustomUnitSystem;
    return await this._api.post<UnitSystem>(url, unitSystemModel).toPromise()
      .then(resp => {
        return API_RESPONSE.SUCCESS;
      })
      .catch(err => {
        return err;
      });
  }

  // deltes the objects from job unit system array matching unit system id
  deleteJobUnitSystemsByUnitSystemId(unitSystemId: string) {
    let jobs = this._utilities.currentUser.settings.jobUnitSystems;
    jobs = jobs.filter(x => x.unitSystemId != unitSystemId);
    this._utilities.currentUser.settings.jobUnitSystems = jobs ? jobs : [];
  }

  // deletes the objects from job unit system array matching job id
  deleteJobUnitSystemsByJobId(jobId: string) {
    let jobs = this._utilities.currentUser.settings.jobUnitSystems;
    jobs = jobs.filter(x => x.jobId != jobId);
    this._utilities.currentUser.settings.jobUnitSystems = jobs ? jobs : [];
  }

  // gets the object from job unit system array matching job id
  getJobUnitSystemByJobId(jobId: string): JobUnitSystem {
    let jobs = this._utilities.currentUser.settings.jobUnitSystems;
    let job = jobs ? jobs.find(x => x.jobId === jobId) : null;
    return job ? job : null;
  }

  // gets the objects from job unit system array matching unit system id
  getJobUnitSystemsByUnitSystemId(unitSystemId: string): JobUnitSystem[] {
    let jobs = this._utilities.currentUser.settings.jobUnitSystems;
    return jobs ? jobs.filter(x => x.unitSystemId === unitSystemId) : [];
  }

  // sets or updates the object in job unit system array
  setJobUnitSystem(jobId: string, unitSystemId: string) {
    this.deleteJobUnitSystemsByJobId(jobId);
    let jobs = this._utilities.currentUser.settings.jobUnitSystems;
    jobs.push(new JobUnitSystem(jobId, unitSystemId));
    this._utilities.currentUser.settings.jobUnitSystems = jobs;
  }

  // set current unit system by cascading global user and job assigned (if any)
  setCurrentUnitSystem(jobId: string) {
    //let job = this._utilities.jobId$.value;
    CURRENT_UNITS.Loading = true;
    CURRENT_UNITS.Loaded = false;

    let unitSystem = this.getCurrentUnitSystem(jobId);
    this._unitConversion.currentUnitSystem$.next(unitSystem);

    CURRENT_UNITS.Name = unitSystem.name;
    CURRENT_UNITS.Loading = false;
    CURRENT_UNITS.Loaded = true;
  }

  // get current unit system by cascading global user and job assigned (if any)
  getCurrentUnitSystem(jobId: string): UnitSystem {
    let job = this.getJobUnitSystemByJobId(jobId);
    let unt = job ? this.findUnitSystem(job.unitSystemId) : this.globalUnitSystem;
    this.isJobUnitSystem = job ? true : false;
    this.updateColumnSettings(unt);
    return unt;
  }

  updateColumnSettings(unitSystem: UnitSystem) {
    var quantities = unitSystem.quantities;
    // disabled, changes display order in units settings pages
    // var common = Array("Percent", "Density", "Volume", "Length", "Diameter", "Depth");
    // common.forEach(x => {
    //   let qty = quantities.find(q => q.quantity === x);
    //   let ndx = quantities.indexOf(qty);
    //   let cut = quantities.splice(ndx, 1)[0];
    //   quantities.splice(0, 0, cut);
    // });

    // updates column settings with current unit
    let activeDepthUnit = this.getActiveDepthUnit(unitSystem);
    Object.keys(ColumnSettingsInfo).forEach(key => {
      ColumnSettingsInfo[key].forEach(column => {
        if (column.quantity && column.quantity != "Unknown") {
          let unitsetting = quantities.find(unitSetting => unitSetting.quantity == column.quantity);
          if (unitsetting) {
            column.currentUnit = unitsetting.selectedUnit;
            if (column.fixedUnit) {
              column.currentUnit = this.getFixedUnit(column.fixedUnit, activeDepthUnit);
            }
          }
        }
      });
    });
  }

  getActiveDepthUnit(unitSystem: any) {
    let res = DEPTH_UNIT.FT;
    let depth = unitSystem.quantities.find(x => x.quantity == UNIT_TYPE_QUANTITY.DEPTH);

    if (depth && depth.selectedUnit) {
      return depth.selectedUnit;
    }

    return res;
  }

  getFixedUnit(fixedUnit, activeDepthUnit): string {
    let unit = fixedUnit.api;

    switch (activeDepthUnit) {
      case DEPTH_UNIT.FT:
        unit = fixedUnit.api;
        break;
      case DEPTH_UNIT.M:
        unit = fixedUnit.si;
        break;
    }
    return unit;
  }

  setUnitSystem(value: UnitSystem) {
    this._unitConversion.currentUnitSystem$.next(value);
  }

  findUnitSystem(id: string): UnitSystem {
    // search  by id, if not found then try by name
    var item = this.unitSystems.find(x => x.unitSystemId === id);
    if (!item) item = this.unitSystems.find(x => x.name === id);
    return item;
  }

  // used by view templates for selected unitsystem
  setSelectedUnitSystem(id: string) {
    this.selectedUnitSystem = deepCopy(this.findUnitSystem(id));
  }

  // used by view templates for selected base unitsystem
  setSelectedBaseUnitSystem(id: string) {
    this.selectedBaseUnitSystem = this.findUnitSystem(id);
  }

  setGlobalUnitSystem(id: string) {
    this.globalUnitSystem = this.findUnitSystem(id);
  }

  setGlobalUomProfile(uomProfile: string) {
    this.globalUomProfile$.next(uomProfile);
  }

  // https://www.npmjs.com/package/thenby
  sortUnitSystems() {
    let u1 = this._utilities.currentUser.settings.unitSystems;
    u1.sort(
      firstBy("unitSystemUserId")
      .thenBy("name", {ignoreCase:true}));
  }

  // used by units resolver routeguard to ensure units are loaded
  waitActiveUnitSystemComplete(jobId: string) {
    return new Observable<string>(ob => {
      //let isApp = this._utilities.isAppInitialized$.value;
      //let isUser = this._utilities.isUserInitialized$.value;
      this._utilities.isAppInitialized$.subscribe(loaded => {
        if (loaded) {
          ob.next("Loading...");
          let unitSystem = this.getCurrentUnitSystem(jobId);
          let currentunitSystem = CURRENT_UNITS.Name;
          //if (this.isJobUnitSystem || unitSystem.name != currentunitSystem) {
            this.setCurrentUnitSystem(jobId);
          //}
          ob.next("Loaded");
          ob.complete();
        }
      });
    });
  }

}
<div [@slideInOut]="show ? 'open' : 'close'" class="project-popup-info" [ngClass]="_utilities.adasThemeClass">
  <div class="job-info-body">
    <div class="modal-header">
      <div class="header h2">{{packet.baralogixName}}</div>
      <button type="button" class="close" aria-label="Close" (click)="onCloseModal()">
        <img class="closeIcon" />
      </button>
    </div>
    <div class="modal-body">
      <div class="job-info">
        <div class="row col-12 info-row">
          <div class="col-4 p-0">{{'region'|translate}}</div>
          <div class="col-8 p-0">{{packet.region}}</div>
        </div>

        <div class="row col-12 info-row">
          <div class="col-4 p-0">{{'country'|translate}}</div>
          <div class="col-8 p-0">{{packet.country}}</div>
        </div>

        <div class="row col-12 info-row">
          <div class="col-4 p-0">{{'createdBy'|translate}}</div>
          <div class="col-8 p-0">{{packet.jobOwner}}</div>
        </div>

        <div class="row col-12 info-row">
          <div class="col-4 p-0">{{'spudDate'|translate}}</div>
          <div class="col-8 p-0">{{packet.spudDate}}</div>
        </div>

        <div class="row col-12 info-row">
          <div class="col-4 p-0">{{'job.jobNumber'|translate}}</div>
          <div class="col-8 p-0">{{packet.jobNumber}}</div>
        </div>

        <div class="row col-12 info-row">
          <div class="col-4 p-0">{{'project.baroidProjectID'|translate}}</div>
          <div class="col-8 p-0">{{packet.baroidProjectNumber}}</div>
        </div>
      </div>

      <div class="packet-info">
        <div (click)="jobDetails(jobs.planning)" class="row packet-info-row border-bottom mx-0">
          <div class="col-8 mb-2 px-0">{{'packetInfo.planningJob'|translate}}</div>
          <div class="col-4 mb-2 px-0 d-flex"><label [ngClass]="getStatusClass(jobs.planning.status)"
              class="small status info-label">{{jobs.planning.status}}</label></div>
        </div>

        <div (click)="jobDetails(jobs.actual)" class="row packet-info-row border-bottom mx-0">
          <div class="col-8 mb-2 px-0">{{'packetInfo.actualJob'|translate}}</div>
          <div class="col-4 mb-2 px-0 d-flex"><label [ngClass]="getStatusClass(jobs.actual.status)"
              class="small status info-label">{{jobs.actual.status}}</label></div>
        </div>

        <div *ngIf="!isEngineer && !techSightJobTypes?.length" class="row packet-info-row border-bottom mx-0">
          <div class="col-8 mb-2 px-0">{{'packetInfo.techSightDos'|translate}}</div>
          <div class="col-4 mb-2 px-0 d-flex"><label 
            class="status-not-created-yet small status info-label">Not Created Yet</label></div>
        </div>

        <div *ngIf="!isEngineer && techSightJobTypes?.length">
          <div *ngFor="let item of techSightJobTypes" (click)="techSightJobDetails(jobs.planning, item)" class="row packet-info-row border-bottom mx-0">
            <div class="col-8 mb-2 px-0">{{'packetInfo.techSightDos'|translate}} - {{item.jobType}}</div>
            <div class="col-4 mb-2 px-0 d-flex"><label [ngClass]="getStatusClass(item.dosStatus)"
                class="small status info-label">{{item.dosStatus}}</label></div>
          </div>          
        </div>

        <div *ngIf="!isEngineer" (click)="techSightJobDetails(jobs.planning)" class="row packet-info-row border-bottom mx-0">
          <div class="col-8 mb-2 px-0">{{'packetInfo.techSightProcessCompliance'|translate}}</div>
          <div class="col-4 mb-2 px-0 d-flex"><label [ngClass]="getStatusClass(jobs.techSightCompliance.status)"
              class="small status info-label">{{jobs.techSightCompliance.status}}</label></div>
        </div>

        <div (click)="jobDetails(jobs.wellSightReport)" class="row packet-info-row border-bottom mx-0">
          <div class="col-8 mb-2 px-0">{{'packetInfo.wellSightReporting'|translate}}</div>
          <div class="col-4 mb-2 px-0 d-flex"><label [ngClass]="getStatusClass(jobs.wellSightReport.status)"
              class="small status info-label">{{jobs.wellSightReport.status}}</label></div>
        </div>

        <div (click)="jobDetails(jobs.hindSightReport)" class="row packet-info-row border-bottom mx-0">
          <div class="col-8 mb-2 px-0">{{'packetInfo.hindSightReporting'|translate}}</div>
          <div class="col-4 mb-2 px-0 d-flex"><label [ngClass]="getStatusClass(jobs.hindSightReport.status)"
              class="small status info-label">{{jobs.hindSightReport.status}}</label></div>
        </div>
      </div>
    </div>
  </div>
</div>